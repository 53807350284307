@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';

.applicant-rsv-verification-card {
    border-radius: @rounded-corner-default;
    box-shadow: 0 2px 12px fade(@color-gray-80, 10%);
    padding: 20px;
    position: relative;
}

@media print {
    .hide-print {
        display: none;
    }
}
