.drop {
    display: flex;
    flex-direction: column;
    padding: 8px;
    white-space: nowrap;
}

.wrapper {
    position: relative;
}
