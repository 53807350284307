@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';

.row {
    @media @screen-gt-s {
        display: flex;
        justify-content: space-between;
    }
}

.row-time {
    display: flex;
    justify-content: space-between;
}

.column,
.column-time {
    @media @screen-gt-s {
        width: 48%;
        margin-top: 0;
    }
}

.column-time {
    width: 48%;
    margin-top: 0;
}

.column {
    margin-top: 16px;

    @media @screen-gt-s {
        margin-top: 0;
    }
}

.column:first-child {
    margin-top: 0;
}

.column:last-child,
.column-time:last-child {
    padding: 0;
}

.calendar {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout {
    @media @screen-gt-s {
        width: 600px;
    }
}

.disclaimer {
    margin-top: 16px;
    padding: 20px;
    border-radius: @rounded-corner-default;
    background: @color-blue-10;

    @media @screen-gt-s {
        margin-top: 25px;
    }
}

.disclaimer-list {
    margin-left: 18px;
}
