@import '~@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.image-table {
    display: flex;
    flex-wrap: nowrap;
    grid-auto-rows: 1fr;
    gap: 12px;

    .screen-gt-xs({
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    });

    .screen-gt-m({
      grid-template-columns: 1fr 1fr 1fr 1fr;
    });
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 142px;
}

.add-card {
    display: flex;
    width: 80px;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 50px 20px;

    .screen-gt-xs({
      width: 118px;
      padding: 50px 24px 50px 24px;
    });
}

.artifact {
    display: flex;
    width: 140px;
    height: 94px;
    object-fit: cover;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    border: 1px solid @magritte-color-stroke-neutral;

    .screen-gt-xs({
      width: auto;
  });
}
