@import '~@hh.ru/magritte-ui/breakpoints';

.corrupted-text {
    display: flex;
    text-align: center;
    justify-content: center;

    .screen-gt-xs({
      margin-right: 12px;
    });
}
