@import '@hh.ru/magritte-ui/breakpoints';

.sticky-sidebar-and-content {
    // Чтобы работало прилипание, высота родителя должна быть
    // больше высоты липкого блока. Выравниваем её с контентом.
    display: flex;
    align-items: stretch;

    // Внутрь могут быть вложены колонки, которые переносятся, если не влезают.
    flex-wrap: wrap;
}

.sticky-sidebar-wrapper {
    // В JS будем использовать `offsetTop` липкого блока.
    position: relative;

    // Чтобы тултипы из сайдбара не залезали под контент.
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;

    // Чтобы работало прилипание внизу родителя, липкий блок
    // должен располагаться внизу родителя (внезапно и неочевидно).
    display: flex;
    flex-direction: column;

    // Контент липкого блока не должен растягивать родителя:
    // https://codepen.io/pen/WNdqZjV
    max-width: 100%;

    // В ситуации, когда сайдбар остаётся один в родительском flex row,
    // без flex-grow он занимает ширину по контенту. Но внутри может быть
    // колонка, которая на XS считает свою ширину от родителя и работает
    // неправильно. Поэтому сайдбар должен занимать всю доступную ширину.
    flex-grow: 1;
}

.sticky-sidebar-helper {
    display: none;
}

.sticky-sidebar-static {
    position: static;
}

.sticky-sidebar-scroll {
    position: relative;
}

.sticky-sidebar-top {
    position: sticky;
    top: 0;
}

.sticky-sidebar-bottom {
    position: sticky;
    bottom: 0;

    // Распорка для прилипания к низу, чтобы липкий блок уехал в конец. Нужен
    // именно дополнительный блок, занимающий всё доступное место перед липким.
    // https://stackoverflow.com/questions/52137323/position-sticky-works-in-chrome-but-not-in-firefox
    + .sticky-sidebar-helper {
        display: block;
        flex-grow: 1;
        order: -1;
    }
}
