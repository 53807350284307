@import '~bloko/common/styles/colors';

.magritte-deep-links {
    display: inline-flex;
    gap: 4px;
    align-items: center;
}

.deep-links {
    display: inline-block;
}

.deep-link {
    display: inline-flex;
    align-items: center;

    margin-right: 16px;

    white-space: nowrap;
    cursor: pointer;
}

.deep-link_whatsapp {
    &:hover {
        color: @color-green-40;
    }
}

.deep-link_viber {
    &:hover {
        color: @color-violet-70;
    }
}

.deep-link_telegram {
    &:hover {
        color: @color-blue-50;
    }
}

.tag {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
}
