@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background-color: @color-green-10;
    border-radius: 4px;
    padding: 20px;
}

@media @screen-lt-m {
    .links {
        flex-direction: column;
        align-items: flex-start;
    }
}
