.phone-container {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}
