@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    padding: 12px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.title {
    display: flex;
    align-items: center;
    gap: 6px;
}

.right {
    display: flex;
    align-items: center;
}

.actions {
    display: flex;
    gap: 6px;
}

.buttons {
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: column;

    &:empty {
        display: none;
    }

    @media @screen-magritte-gt-s {
        flex-direction: row;
    }
}
