@import '~@hh.ru/magritte-ui/base-variables';

.list {
    display: flex;
    flex-wrap: wrap;
    gap: @magritte-core-space-3-x;
    align-items: center;
}

.link {
    max-width: 288px;
}
