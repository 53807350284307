@import '@hh.ru/magritte-ui/breakpoints';

.tooltip-text {
    white-space: normal;
}

.select-wrapper {
    width: 150px;

    .screen-gt-xs({
        width: 300px;
    });
}

.select-bloko-wrapper {
    display: flex;
}

.selector-link {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
