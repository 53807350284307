@import '~@hh.ru/magritte-ui/base-variables';
@import '~@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: @magritte-core-space-3-x;
    align-items: center;
}

.text-wrapper {
    flex: 1 1 100%;

    .screen-gt-s({
        flex: 0 1 auto;
    });
}
