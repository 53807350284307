@import '~bloko/common/styles/colors';

.level-title {
    display: flex;
    align-items: center;
    gap: 6px;
}

.level-tip {
    display: flex;
    padding: 16px;
    background-color: @color-gray-10;
}

.modal-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.modal-item {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid @color-gray-30;
}

.modal-list {
    list-style: inside;
    padding-left: 8px;
}
