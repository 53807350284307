@radios-gap: 16px;
@buttons-gap: 8px;

.radios-wrapper {
    display: flex;
    gap: @radios-gap;

    > label {
        flex: 1 1 calc(calc(100% - @radios-gap) / 2);
    }
}

.buttons-wrapper {
    display: flex;
    gap: @buttons-gap;

    > button {
        flex: 1 1 calc(calc(100% - @buttons-gap) / 2);
    }
}
