@import '~@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.artifact {
    display: flex;
    width: 96px;
    height: 96px;
    object-fit: cover;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    border: 1px solid @magritte-color-stroke-neutral;
}

.empty-artifacts {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.empty-image {
    margin-top: 32px;
    margin-bottom: 30px;
    width: 190px;
    height: 220px;
    background: url('empty.svg') no-repeat;
}
