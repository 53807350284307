@import '~@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.answers {
    display: flex;
    flex-wrap: wrap;
    gap: @magritte-core-space-2-x;
}

.empty {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
}
