@import '@hh.ru/magritte-ui/breakpoints';
@import '~bloko/common/styles/colors';

.card-wrapper {
    box-sizing: border-box;
    height: 180px;
    width: 165px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .screen-gt-xs({
        width: auto;
    });
}

.image-wrapper {
    width: 100%;
    height: 96px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        // Магритт токены не работают вместе с fade, так как являются ccs-переменными, а не значениеями
        // Использовал блоко токены, ориeнтируюясь на этот тред – https://mattermost.pyn.ru/hhru/pl/tekoewu5cbrdfgzzpspk3p5iqc
        background: linear-gradient(
            180deg,
            transparent 42.5%,
            fade(@color-special-absolute-black, 6%) 63.5%,
            fade(@color-special-absolute-black, 10%) 75.5%,
            fade(@color-special-absolute-black, 15%) 87.5%,
            fade(@color-special-absolute-black, 25%) 100%
        );
    }
}

.image-wrapper_vertical-stretched {
    height: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
