@import '@hh.ru/magritte-ui/tokens';

.backoffice-card-top {
    padding: 24px;
}

.backoffice-card-content {
    padding: 24px 24px 12px;
}

.divider {
    border-top: 1px solid @magritte-color-stroke-neutral;
    width: 100%;
    height: 0;
}

.buttons {
    display: flex;
    gap: 12px;
}

.button {
    flex: 1;
}
