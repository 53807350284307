@import '~@hh.ru/magritte-ui/breakpoints';

.tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.skills {
    padding: 12px;
}

.footer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .screen-gt-s({
        flex-direction: row;
    });
}
