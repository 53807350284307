@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/text-base';

.pinned-messages {
    border-left: 2px solid @color-blue-60;
    padding-left: 16px;
    max-width: 420px;
    white-space: pre-wrap;
}

.clipped-message {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: @body-line-height * 3;
}
