.levels-cards-row {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: stretch;
}

.levels-card-text-container {
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
