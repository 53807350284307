.wrapper {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    max-width: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 24px;
}
