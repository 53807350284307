.body {
    display: flex;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    justify-content: space-between;
}

.edit {
    @media print {
        display: none;
    }
}

.details {
    vertical-align: top;
}
