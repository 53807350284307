@import '~bloko/common/styles/colors';

.outdated {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 12px;
    gap: 12px;
    background-color: @color-yellow-10;
}

.text {
    width: 100%;
}
