.icon {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
}

.wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 4px;
    align-items: center;
}

.one-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 4px;
}
