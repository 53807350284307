@import '~@hh.ru/magritte-ui/base-variables';
@import '~@hh.ru/magritte-ui/breakpoints';

.card {
    display: flex;
    gap: @magritte-core-space-4-x;
    align-items: center;

    flex-direction: column;

    .screen-gt-s({
        flex-direction: row;
        gap: @magritte-core-space-6-x;
        width: 100%
    });
}

.card-title {
    flex: 1;
}
