@import '@hh.ru/magritte-ui/tokens';

.banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 12px 12px 20px;
    min-height: 64px;
    background-color: @magritte-color-background-secondary;
    border-radius: 20px;
    box-sizing: border-box;
}

.limited-width {
    max-width: 496px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.button-wrapper {
    width: 52px;
}
