@import '~@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.card {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-3-x;

    .screen-gt-s({
        flex-direction: row;
        align-items: center;
    });
}

.card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-4-x;
    padding: @magritte-core-space-3-x;

    .screen-gt-s({
        padding: 0;
    });
}
