.search-list-card {
    position: relative;
    width: 100%;
}

.search-list-content {
    display: flex;
    gap: 12px;
    align-items: center;
}

.search-list-text {
    display: flex;
    flex-direction: column;
}

.pagination {
    align-self: center;
    margin-top: auto;
    margin-bottom: 8px;
}

.centered {
    flex-grow: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
