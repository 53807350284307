.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 0 24px;

    &:empty {
        display: none;
    }
}
