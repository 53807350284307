.container-only-text {
    display: flex;
    align-items: center;
    max-width: 150px;
    text-align: center;
}

.container-with-icon {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
}

.play-icon {
    background: url('play-icon.svg');
    width: 20px;
    height: 20px;
}
