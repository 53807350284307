.wrapper {
    // Кастом пропа space-between=false из 3й версии Button, которую пока не подвезли в веб
    // TODO: Выпилить – https://jira.hh.ru/browse/HH-240289
    // По сути это хак, чтобы растянуть кнопки без использования атрибута stretched
    // Так как из-за него постфиксы и иконки отображаются в разных углах кнопки, а нам нужны в центре
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}
