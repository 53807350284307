@import '~@hh.ru/magritte-ui/base-variables';
@import '~@hh.ru/magritte-ui/breakpoints';

.root {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-2-x;

    .screen-gt-s({
        flex-direction: row;
        gap: @magritte-core-space-6-x;
    });
}

.year {
    min-width: 184px;
}

.hint {
    .screen-gt-s({
        display: flex;
        align-items: center;
    });
}
