@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/base-variables';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-3-x;

    .screen-gt-s({
        flex-direction: row;
        align-items: center;
        gap: @magritte-core-space-4-x;
    });
}

.content {
    flex: 1 1 auto;
    padding: @magritte-core-space-3-x;

    .screen-gt-s({
        padding: 0
    });
}
