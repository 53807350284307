.adaptive-buttons-container {
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
}

.adaptive-buttons-width-computer {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.adaptive-buttons-spacer {
    flex-grow: 1;
}

.is-hidden {
    visibility: hidden;
}
