.content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 24px;
}
