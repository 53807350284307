@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    margin: 12px 0 0;
    display: flex;
    justify-content: center;

    .screen-gt-s({
        justify-content: flex-start;
    });
}
