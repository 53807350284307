@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;

    .screen-gt-xs({
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    });
}
