@import '~@hh.ru/magritte-ui/base-variables';
@import '~@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    flex-direction: column;
    margin-top: @magritte-core-space-3-x;
    gap: @magritte-core-space-6-x;
}

.item,
.input {
    flex: 1;
}
