@import '~@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
}

.image-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 8px;

    .screen-gt-xs({
      grid-template-columns: 1fr 1fr 1fr 1fr;
    });

    .screen-gt-s({
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    });
}

.artifact {
    display: flex;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    aspect-ratio: 1 / 1;
    align-items: center;
    justify-content: center;
}

.corrupted {
    background: @magritte-color-background-secondary;
}
