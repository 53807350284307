@import '@hh.ru/magritte-ui/base-variables';

.card {
    display: flex;
    padding: 12px;
    gap: 16px;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: @magritte-core-space-3-x;
}
