@import '~@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    align-items: center;
    gap: @magritte-core-space-4-x;
    padding: @magritte-core-space-2-x @magritte-core-space-3-x 0;

    .screen-gt-s({
        gap: @magritte-core-space-4-x;
        padding: @magritte-core-space-2-x @magritte-core-space-3-x @magritte-core-space-1-x;
    });
}

.text {
    flex-grow: 1;
    padding-top: @magritte-core-space-1-x;

    .screen-gt-s({
        gap: @magritte-core-space-4-x;
        padding-top: 0
    });
}
