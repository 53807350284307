@import '~bloko/common/styles/media/_screen';

.cards-container {
    display: flex;
    flex-direction: column;
}

.cards-wrapper {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr;

    @media @screen-gt-s {
        grid-template-columns: 1fr 1fr;
    }
}

.card {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 12px;
    align-items: center;
}
