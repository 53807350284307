@import '~@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.portfolio {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.artifact {
    display: flex;
    height: 343px;
    object-fit: contain;
    border-radius: 32px;
    align-items: center;
    justify-content: center;
    border: 1px solid @magritte-color-stroke-neutral;

    .screen-gt-xs({
      height: 384px;
  });
}
