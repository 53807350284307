@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_column-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.open-contacts-container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 0;
}

.hh-pro-background {
    background: @color-gray-10;
    text-align: left;

    @media @screen-magritte-gt-s {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.hh-pro-content {
    max-width: calc(100% - 70px);

    @media @screen-magritte-gt-s {
        max-width: calc(100% - 50px);
    }
}

.hh-pro-image-container {
    position: relative;
    overflow: hidden;
}

.hh-pro-image {
    background: no-repeat left center;
    background-size: cover;
    background-image: url('./images/hhpro.png');
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 123px;
}
