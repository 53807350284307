@import '@hh.ru/magritte-ui/breakpoints';

.drop-content {
    .screen-gt-s({
        width: 252px;
    });
}

.folders-container {
    .screen-gt-s({
        max-height: 200px;
        overflow: auto;
    });
}

.new-folder-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}
