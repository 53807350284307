@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/base-variables';

.gifts-promo-content-spacer {
    flex-grow: 1;
}

.content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: @magritte-static-space-300;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: @magritte-static-space-300;
}

.logo {
    width: 92px;
    height: 32px;

    .screen-gt-xs({
        width: 104px;
        height: 36px;
    });
}

.qr-code {
    align-self: center;
    display: block;
    width: 75px;
    height: $width;
    padding: 16px;
    border-radius: 12px;
    background-color: @magritte-color-background-primary;
}
