.loader {
    display: flex;
    flex-grow: 1;
    min-height: 200px;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.full-screen-modal-content-wrapper {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;

    // убрать после релиза TODO: https://jira.hh.ru/browse/PORTFOLIO-34473
    // от высоты экрана отнимаем высоту хедера, футера и паддинги и получаем максимальный размер контента
    min-height: calc(100vh - 184px - 24 * 2px);
}

.full-screen-modal-content-wrapper-with-options {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;

    // убрать после релиза TODO: https://jira.hh.ru/browse/PORTFOLIO-34473
    // как выше но еще минус высота опций (считаем что она такая)
    min-height: calc(100vh - 184px - 24 * 2px - 72px);
}
