.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
}

.scrollable-wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
}
